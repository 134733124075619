<template>
  <div class="edp-reportApp">
    <section class="edp-reportApp__tags">
      <div
        class="edp-reportApp__tags__item"
        v-for="(item, index) in tagList"
        :key="'appTag' + index"
        :class="{ selected: item.selected }"
        @click="tagChange(index)"
      >
        {{ item.name }}
      </div>
    </section>

    <section class="edp-reportApp__list">
      <div
        class="edp-reportApp__list__item"
        v-for="(item, index) in pageList"
        :key="'appList' + index"
        @click="linkTo(item)"
      >
        <img :src="page_birth_bg" alt="" v-if="item.itemType === 'birthday'" />
        <img :src="page_any_bg" alt="" v-if="item.itemType === 'anniversary'" />
        <img :src="page_an_bg" alt="" v-if="item.itemType === 'annual'" />

        <div class="edp-reportApp__list__item__name">
          <span v-if="item.itemType === 'birthday'">
            {{ item.currentYear }} <br />
            {{ $t("reportApp.in_birth") }}
          </span>

          <span v-if="item.itemType === 'anniversary'">
            {{
              $i18n.locale === "cn"
                ? item.yearCount
                : utils.stNdTh(item.yearCount)
            }}
            <br />
            {{ $t("reportApp.in_yearZhou") }}
          </span>

          <span v-if="item.itemType === 'annual'">
            {{ item.yearCount }} <br />
            {{ $t("reportApp.in_yearEnd") }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import an_bg from "@/src/assets/reportCover/app/an_bg.jpg";
import any_bg from "@/src/assets/reportCover/app/any_bg.jpg";
import birth_bg from "@/src/assets/reportCover/app/birth_bg.jpg";

export default {
  name: "reportApp",
  data() {
    return {
      currentTag: "",
      tagList: [
        { name: this.$t("reportApp.tag_total"), selected: true, type: "" },
        { name: this.$t("reportApp.tag_birth"), type: "birthday" },
        { name: this.$t("reportApp.tag_yearZhou"), type: "anniversary" },
        { name: this.$t("reportApp.tag_yearEnd"), type: "annual" },
      ],
      pageList: [],

      pageList_total: [],
      pageList_an: [],
      pageList_any: [],
      pageList_birth: [],

      pageType: "",

      page_an_bg: an_bg,
      page_any_bg: any_bg,
      page_birth_bg: birth_bg,
    };
  },
  methods: {
    ...mapActions({
      reportListInit: "reportApp/reportListInit",
    }),
    async pageInit() {
      let res = await this.reportListInit();

      this.pageList_an =
        res.data && res.data.data && res.data.data.empYearReports
          ? res.data.data.empYearReports
          : [];

      this.pageList_an.forEach((val) => {
        this.$set(val, "itemType", "annual");
      });

      this.pageList_any =
        res.data && res.data.data && res.data.data.empYearAnniversary
          ? res.data.data.empYearAnniversary
          : [];

      this.pageList_any.forEach((val) => {
        this.$set(val, "itemType", "anniversary");
      });

      this.pageList_birth =
        res.data && res.data.data && res.data.data.empBirthdays
          ? res.data.data.empBirthdays
          : [];

      this.pageList_birth.forEach((val) => {
        this.$set(val, "reportGenTime", val.etlTime);
        this.$set(val, "itemType", "birthday");
      });

      this.pageList_total = this.pageList_an
        .concat(this.pageList_any)
        .concat(this.pageList_birth);

      let pageList_totalLs = JSON.parse(JSON.stringify(this.pageList_total));

      this.pageList_total.forEach((val, e) => {
        let TimeOut = new Date(val.reportGenTime).getTime();
        let valueOut = val;

        pageList_totalLs.forEach((val2, e2) => {
          let TimeIn = new Date(val2.reportGenTime).getTime();
          let valueIn = val2;

          if (TimeOut < TimeIn) {
            val = valueIn;
            val2 = valueOut;
          }
        });
      });

      this.tagChange(0);

      this.$forceUpdate();
    },
    tagChange(index) {
      this.pageType = this.tagList[index].type;

      this.tagList.forEach((val, e) => {
        if (e == index) {
          this.currentTag = e;
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });

      if (this.pageType === "birthday") {
        this.pageList = this.pageList_birth;
      } else if (this.pageType === "anniversary") {
        this.pageList = this.pageList_any;
      } else if (this.pageType === "annual") {
        this.pageList = this.pageList_an;
      } else {
        this.pageList = this.pageList_total;
      }
    },
    linkTo(item) {
      if (item.itemType === "annual") {
        this.$router.push({
          name: "reportApp_annual",
          query: { currentYear: item.yearCount },
        });
      }
    },
  },
  mounted() {
    this.pageInit();
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-reportApp {
  width: 100%;
  min-height: 100%;
  background: url("~@/src/assets/reportCover/app-bg.png") no-repeat center top;
  background-size: 100% auto;

  .edp-reportApp__tags {
    display: flex;
    width: 100%;

    .edp-reportApp__tags__item {
      display: inline-block;
      position: relative;
      flex-shrink: 0;
      width: 25%;
      text-align: center;
      padding: toRem(24) 0 toRem(8) 0;
      cursor: pointer;
      font-size: toRem(12);
      color: #9b9a9b;

      &::after {
        display: none;
        position: absolute;
        content: "";
        width: toRem(28);
        height: toRem(2);
        overflow: hidden;
        background: #aa9077;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }

      &.selected {
        color: #000;

        &::after {
          display: inline-block;
        }
      }
    }
  }

  .edp-reportApp__list {
    position: relative;
    display: flex;
    width: toRem(352);
    flex-wrap: wrap;
    padding: toRem(24) 0;
    margin: 0 auto;

    .edp-reportApp__list__item {
      position: relative;
      width: toRem(163);
      height: toRem(200);
      overflow: hidden;
      flex-shrink: 0;
      margin: 0 toRem(3) toRem(10) toRem(5);
      background: #fff;
      cursor: pointer;

      img {
        display: block;
        width: auto;
        height: toRem(144);
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 0;
      }
      .edp-reportApp__list__item__name {
        width: 100%;
        height: auto;
        padding: toRem(149) toRem(15) 0 toRem(15);
        font-size: toRem(16);
        line-height: 1.4;
        z-index: 1;
      }
    }
  }
}
</style>
